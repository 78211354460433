import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import AdminSideBar from './AdminSlideBar';
import DashboardLanding from './DashboardLanding';
import '../../styles/dashboard.scss';
import ServiceIndex from './service/ServiceIndex';
import ViewService from './service/ViewService';
import MdMessageIndex from './MDMessage/MdMessageIndex';
import ApplicationIndex from './application/ApplicationIndex';
import ViewApplication from './application/ViewApplication';
import ContactIndex from './contact/ContactIndex';
import ViewContact from './contact/ViewContact';
import SliderIndex from './slider/SliderIndex';
import GalleryIndex from './gallery/GalleryIndex';
import PopIndex from './pop/PopIndex';
import UpdatePop from './pop/UpdatePop';
import UpdateService from './service/UpdateService';
import TeamIndex from './team/TeamIndex';
import UpdateTeam from './team/UpdateTeam';
import UploadIndex from './upload/UploadIndex';
import JobIndex from './job/JobIndex';
import ViewJob from './job/ViewJob';
import UpdateJob from './job/UpdateJob';
import JobApplicationIndex from './jobApplication/JobApplicationIndex';
import ViewJobApplication from './jobApplication/ViewJobApplication';
import SecurityIndex from './security/SecurityIndex';
import ReviewIndex from './reviews/ReviewIndex';
import ViewReview from './reviews/ViewReview';
import UpdateReview from './reviews/UpdateReview';

const 
 Dashboard = () => {
    return (
        <>
            
            <section className="dashboard-section">
                <div className="dashboard-parent-wrapper">
                    <div className="dashboard-childs-wrapper">
                        <AdminSideBar />
                    </div>
                    <div className="dashboard-childs-wrapper">
                        <div className="dashboard-header-wrapper">
                            <div className="dashboard-header-childs">
                                <h3>EDashboard - <span>Welcome Admin</span></h3>
                            </div>
                            <div className="dashboard-header-childs">
                                <ul>
                                    <li><Link to="/dashboard/"><i className="fa fa-chart-line"></i>Report</Link></li>
                                    <li><Link to="/dashboard/manageApplication"><i className="fa fa-bell"></i></Link></li>
                                    <li><Link to="/dashboard/manageContact"><i className="fa fa-comment"></i></Link></li>
                                    <li><Link to="/dashboard/"><i className="fa fa-cog"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="dashboard-content-wrapper">
                            <Routes>
                                <Route path="/" element={<DashboardLanding />} />
                                <Route path="/manageService" element={<ServiceIndex />} />
                                <Route path="/updateService/:id" element={<UpdateService />} />
                                
                                <Route path="/viewService/:id" element={<ViewService />} />
                                <Route path="/manageMdMessage" element={<MdMessageIndex />} />
                                
                                <Route path="/manageApplication" element={<ApplicationIndex />} />
                                <Route path="/viewApplication/:id" element={<ViewApplication />} />
                                
                                <Route path="/manageContact" element={<ContactIndex />} />
                                <Route path="/viewContact/:id" element={<ViewContact />} />

                                <Route path="/manageSlider" element={<SliderIndex />} />

                                <Route path="/manageGallery" element={<GalleryIndex />} />

                                <Route path="/managePop" element={<PopIndex />} />
                                <Route path="/updatePop/:id" element={<UpdatePop />} />

                                <Route path="/manageTeam" element={<TeamIndex />} />
                                <Route path="/updateTeam/:id" element={<UpdateTeam />} />

                                <Route path="/manageUpload" element={<UploadIndex />} />

                                <Route path="/manageJob" element={<JobIndex />} />
                                <Route path="/viewJob/:id" element={<ViewJob />} />
                                <Route path="/updateJob/:id" element={<UpdateJob />} />

                                <Route path="/manageJobApplication" element={<JobApplicationIndex />} />
                                <Route path="/viewJobApplication/:id" element={<ViewJobApplication />} />

                                <Route path="/changePassword" element={<SecurityIndex />} />

                                <Route path="/manageReviews" element={<ReviewIndex />} />
                                <Route path="/viewReview/:id" element={<ViewReview />} />
                                <Route path="/updateReview/:id" element={<UpdateReview />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard