import React from 'react';
import { useNavigate } from 'react-router-dom';
import decoImage from '../../assets/images/design/dotted_orange.png';
import graduation from '../../assets/images/graduation.jpg';
import nepal from '../../assets/images/partners/nepal.png';
import fecon from '../../assets/images/partners/fecon.png';

const About = () => {
    const navigate = useNavigate();

    const sendMeToAbout = () => {
        return navigate("/about");
    }
    return (
        <div className="about-section">
            <img src={decoImage} className="about-deco-image" alt="deco" />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="about-info">
                            <div className="studymate-header">
                                <h4>ABOUT US</h4>
                            </div>
                            <div className="about-info-wrapper">
                                <p className="studymate-explain">StudyMate International Educational Consultancy is your true education partner which is located in Pokhara Metropolitan City, Ward No. 1, Bagar, Kaski, Gandaki Province, Nepal. Your dream of studying abroad only can be possible when you choose the right educational consultancy.....</p>
                            </div>
                            <div className="why-choose-studymate-wrapper">
                                <div className="why-choose">
                                    <div className="why-choose-icon-wrapper">
                                        <i className="fa fa-graduation-cap"></i>
                                    </div>
                                    <h3>Qualified Instructor</h3>
                                </div>
                                <div className="why-choose">
                                    <div className="why-choose-icon-wrapper">
                                        <i className="fa fa-shield-alt"></i>
                                    </div>
                                    <h3>Certified Counselor</h3>
                                </div>
                                <div className="why-choose">
                                    <div className="why-choose-icon-wrapper">
                                        <i className="fa fa-check-circle"></i>
                                    </div>
                                    <h3>Reliable Services</h3>
                                </div>
                            </div>
                        </div>
                        <div className="studymate-approved-by-wrapper">
                            <div className="approved-by-heading">
                                <h3>Approved By:</h3>
                                <span>Ministry of Education of Nepal & FECON</span>
                            </div>
                            <div className="approved-image">
                                <div className="approved-image-child">
                                    <img src={nepal} draggable={false} alt="nepal" />
                                </div>
                                <div className="approved-image-child">
                                    <img src={fecon} draggable={false} alt="fecon" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-bg-image">
                            <img src={graduation} alt="about-studymate" />
                        </div>
                        <div className="know-more-btn">
                            <button onClick={sendMeToAbout}>Know more about us <i className="fa fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About