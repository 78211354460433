import React from 'react';
import { useNavigate } from 'react-router-dom';
import top_deco_image from '../../assets/images/svgs/top-deco-svg.svg';

const TopNavigation = () => {
    const navigate = useNavigate();

    const sendToApplication = () => {
        return navigate("/application");
    }

    const sendToContact = () => {
        return navigate("/contact");
    }

    return (
        <section className="studymate-top-navigation">
            <div className="top-navigation-deco">
                <img src={top_deco_image} alt="deco" />
            </div>
            <div className="top-navigation-info">
                <div className="container">
                    <div className="row">
                        <div className="top-navigation-parent">
                            <div className="top-navigation-childs">
                                <ul>
                                    <li><i className="fa fa-phone"></i>+977-61-570898</li>
                                    <li><i className="fa fa-envelope"></i>info@studymate.edu.np</li>
                                    <li><i className="fa fa-map-marker-alt"></i>Pokhara-1, Bagar, Kaski</li>
                                </ul>
                                <div className="mobile-view-contact-details-wrapper">
                                    <div className="mobile-view-contact-detail-icon"><i className="fa fa-angle-down"></i></div>
                                    <div className="mobile-view-contact-details">
                                        <div className="mobile-view-contact-detail"><i className="fa fa-phone"></i>+977-61-570898</div>
                                        <div className="mobile-view-contact-detail"><i className="fa fa-envelope"></i>info@studymate.edu.np</div>
                                        <div className="mobile-view-contact-detail"><i className="fa fa-map-marker-alt"></i>Pokhara-1, Bagar, Kaski</div>
                                    </div>
                                </div>
                            </div>
                            <div className="top-navigation-childs">
                                <ul>
                                    <li>
                                        <button onClick={sendToContact}>Callback Request</button>
                                    </li>
                                    <li>
                                        <button onClick={sendToApplication} className="applyBtn">Apply Now <i className="fa fa-angle-right"></i></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TopNavigation