import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import brandLogo from '../../assets/images/studyMateLogo.png';
import { getService } from '../../state/actions/serviceAction';
import Loading from '../loader/Loader';
import { toggleAction } from '../../state/actions/frontendAction';

const Navigation = () => {
    const dispatch = useDispatch();

    const { loading, services } = useSelector(state => state.services);
    const { toggle } = useSelector(state => state.frontend);

    const manageToggle = (val) => {
        return dispatch(toggleAction(val));
    }

    useEffect(() => {
        dispatch(getService("all"));
    }, [dispatch]);
    return (
        <>
            {loading ? <Loading /> : <></>}
            <section className="navigation-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="menu">
                                <div className="menu-childs">
                                    <Link to="/">
                                        <img draggable={false} src={brandLogo} alt="studymate_logo" />
                                    </Link>
                                    <div className="toggle-open-btn"><i onClick={() => manageToggle(true)} className="fa fa-bars"></i></div>
                                </div>
                                <div className={toggle ? "menu-childs" : "menu-childs leftVal"}>
                                    <div className="toggle-close-btn"><i onClick={() => manageToggle(false)} className="fa fa-times"></i></div>
                                    <ul>
                                        <li>
                                            <NavLink to="/" onClick={() => manageToggle(false)}>Home</NavLink>
                                        </li>
                                        <li className="sub-menu-wrapper">
                                            <NavLink>About <i className="fa fa-angle-down"></i>
                                                <div className="sub-menu">
                                                    <ol><NavLink onClick={() => manageToggle(false)} to="/about"><i className="fa fa-angle-right"></i>About Us</NavLink></ol>
                                                    {/* <ol><NavLink onClick={() => manageToggle(false)} to="/branch/finland"><i className="fa fa-angle-right"></i>Branch Finland</NavLink></ol>
                                                    <ol><NavLink onClick={() => manageToggle(false)} to="/branch/hemja"><i className="fa fa-angle-right"></i>Branch Hemja</NavLink></ol> */}
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li className="sub-menu-wrapper">
                                            <NavLink>
                                                Study Abroad <i className="fa fa-angle-down"></i>
                                                <div className="sub-menu">
                                                    {
                                                        services && services.filter(opt => opt.category === "Study Abroad").map((data, index) => (
                                                            <ol key={index}><NavLink onClick={() => manageToggle(false)} className="active-class-handler" to={`/serviceDetail/${data.id}`}><i className="fa fa-angle-right"></i><img src={data.image} alt="featured_img" />{data.title}</NavLink></ol>
                                                        ))
                                                    }
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li className="sub-menu-wrapper">
                                            <NavLink>Preparation Classes <i className="fa fa-angle-down"></i>
                                                <div className="sub-menu">
                                                    {
                                                        services && services.filter(opt => opt.category === "Preparation Classes").map((data, index) => (
                                                            <ol key={index}><NavLink onClick={() => manageToggle(false)} className="active-class-handler" to={`/serviceDetail/${data.id}`}><i className="fa fa-angle-right"></i><img src={data.image} alt="featured_img" />{data.title}</NavLink></ol>
                                                        ))
                                                    }
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li className="sub-menu-wrapper">
                                            <NavLink>Language Classes <i className="fa fa-angle-down"></i>
                                                <div className="sub-menu">
                                                    {
                                                        services && services.filter(opt => opt.category === "Language Classes").map((data, index) => (
                                                            <ol key={index}><NavLink onClick={() => manageToggle(false)} className="active-class-handler" to={`/serviceDetail/${data.id}`}><i className="fa fa-angle-right"></i><img src={data.image} alt="featured_img" />{data.title}</NavLink></ol>
                                                        ))
                                                    }
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/gallery" className="active-class-handler" onClick={() => manageToggle(false)}>Gallery</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/career" className="active-class-handler" onClick={() => manageToggle(false)}>Career</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/contact" className="active-class-handler" onClick={() => manageToggle(false)}>Contact</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Navigation