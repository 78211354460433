import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.scss';
import App from './App';
import Dashboard from './pages/dashboard/Dashboard';
import ProtectedRoutes from './components/protectedRoutes/ProtectedRoutes';
import Scroll from './components/scrollManager/Scroll';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <HashRouter>
      <Provider store={store}>
        <Scroll />
        <Routes>
          <Route path="/*" element={<App />} exact />
          <Route element={<ProtectedRoutes />}>
            <Route path="/dashboard/*" element={<Dashboard />} />
          </Route>
        </Routes>
      </Provider>
    </HashRouter>
  </>
);

