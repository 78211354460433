import { useEffect, useState } from "react";
import Notify from "../../pages/dashboard/notify/Notify";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, creat_review, resetNew } from "../../state/actions/reviewAction";
import Loader from "../loader/Loader";
import reviews from "../../assets/images/reviews.png";

const ReviewForm = ({ setIsForm }) => {
    const dispatch = useDispatch();
    const [rating, setRating] = useState(4);
    const [showSuccess, setShowSuccess] = useState(false);

    const finalCall = () => {
        setShowSuccess(false);
        setIsForm(false);
    }

    const { loading, success, error } = useSelector(state => state.newReview);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [reviewVal, setReviewVal] = useState({
        name: "",
        review: "",
        orientation: 1,
        visibility: "hide"
    });

    const [file, setFile] = useState(null);

    const onChangeHandler = (e) => {
        return setReviewVal({ ...reviewVal, [e.target.name]: e.target.value })
    }

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const submitHandler = () => {

        if (!reviewVal.name || !reviewVal.review) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Mandatory field is missing!"
            });
        };

        if (file === null) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please provide your picture"
            });
        };

        if ((file.size / 1024) >= 800) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "File size should be less than '800kb'!"
            });
        };

        if ((file.size / 1024) >= 800) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "File size should be less than '800kb'!"
            });
        };

        const myForm = new FormData();

        myForm.append("name", reviewVal.name);
        myForm.append("rating", rating);
        myForm.append("review", reviewVal.review);
        myForm.append("visibility", reviewVal.visibility);
        myForm.append("orientation", reviewVal.orientation);
        myForm.append("image", file);

        return dispatch(creat_review(myForm));
    }

    useEffect(() => {
        if (error) {
            dispatch(clearErrors())
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
        }
        if (success) {
            dispatch(resetNew());
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: "Thank you for your review! Your review will display in our site soon."
            });
            setShowSuccess(true)
        }
    }, [dispatch, success, error]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <div className="review-form-wrapper">
                <div className="review-form-inner-wrapper">
                    <div className="review-close-button">
                        <button onClick={() => setIsForm(false)}>Close</button>
                    </div>
                    <form className="global-form" onSubmit={(e) => e.preventDefault()}>
                        <div className="container-fluid">
                            <h3 className="review-heading">Submit your review</h3>
                            {
                                isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                            }
                            <p className="review-des">
                                Thank you for taking the time to share your thoughts with us! Your review means a lot, and we truly appreciate your feedback. If you have any more insights or suggestions, feel free to let us know. We're here to make your experience even better.
                            </p>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <label className="label">Enter Your Name</label><span>*</span>
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Enter Full Name"
                                            name="name"
                                            value={reviewVal.name}
                                            onChange={(e) => onChangeHandler(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="input-group rating-sec">
                                        <p>Rating - </p>
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <span
                                                key={star}
                                                style={{ cursor: 'pointer', fontSize: '24px' }}
                                                onClick={() => handleRatingChange(star)}
                                            >
                                                {star <= rating ? '★' : '☆'}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <label className="label">Review</label><span>*</span>
                                        <textarea
                                            cols="30"
                                            rows="10"
                                            name="review"
                                            value={reviewVal.review}
                                            onChange={(e) => onChangeHandler(e)}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <label className="label">Choose Image</label><span>*</span>
                                        <input
                                            className="input"
                                            type="file"
                                            accept="image/jpeg, image/png"
                                            onChange={(e) => setFile(e.target.files[0])}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="submit-btn">
                                        <button onClick={() => submitHandler()}>Submit <i className="fa fa-paper-plane"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {
                showSuccess
                    ?
                    <div className="review-form-wrapper">
                        <div className="review-form-inner-wrapper">
                            <div className="review-form-image">
                                <h3>Thank you for your review! Your review will display in our site soon.</h3>
                                <img src={reviews} alt="logo" />
                                <button onClick={() => finalCall()}>Done</button>
                            </div>
                        </div>
                    </div>
                    :
                    <></>

            }
        </>
    )
};

export default ReviewForm;