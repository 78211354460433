import review from "../../assets/images/reviews.png";
import Slider from "react-slick";
import ReviewForm from "../../components/review/ReviewForm";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReview } from "../../state/actions/reviewAction";
import moment from "moment";

const Review = () => {
    const dispatch = useDispatch();
    const { reviews } = useSelector(state => state.reviews);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [isForm, setIsForm] = useState(false);

    useEffect(() => {
        dispatch(getReview())
    }, [dispatch]);

    return (
        <>
            {
                isForm ? <ReviewForm setIsForm={setIsForm} /> : <></>
            }

            <section className="testimonial-section">
                <div className="studymate-header studymate-header-center">
                    <h4>TESTIMONIAL</h4>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="review-slider">
                                <Slider {...settings}>
                                    {
                                        reviews?.filter((data) => data?.visibility === "visible")?.map((data, index) => (
                                            <div className="review-card-wrapper" key={index}>
                                                <div className="review-card-header">
                                                    <div className="review-card-header-childs">
                                                        <div className="review-card-header-image">
                                                            <img src={data?.image} alt="reviews" />
                                                        </div>
                                                    </div>
                                                    <div className="review-card-header-childs">
                                                        <h3>{data?.name}</h3>
                                                        <span><i className="fa fa-check-circle"></i> Verified</span>
                                                        <p>{moment(data?.created_at).format('MMM-D, YYYY')}</p>
                                                        <div className="ratings">
                                                            {
                                                                data?.rating && (
                                                                    <ul className="rating-list">
                                                                        {[...Array(data.rating)].map((_, index) => (
                                                                            <li key={index} className="fa fa-star"></li>
                                                                        ))}
                                                                    </ul>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="review-description">
                                                    <p>
                                                        {data?.review}
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="review-image">
                                <img src={review} alt="reviews" />
                            </div>
                            <div className="add-review-btn">
                                <button onClick={() => setIsForm(true)}>Add Your Review</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Review;