import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notify from '../../pages/dashboard/notify/Notify';
import logo from '../../assets/images/studyMateLogo.png';
import apply from '../../assets/images/svgs/apply_feature.svg';
import arrow from '../../assets/images/svgs/arrow.svg';
import { createApplication, resetNew } from '../../state/actions/applicationAction';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/Loader';

const Application = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, success, error } = useSelector(state => state.newApplication);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [applicationVal, setApplicationVal] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
        interestedfor: "",
        country: "",
        message: "",
    });
    const [isAgree, setIsAgree] = useState(false);

    const onChangeHandler = (e) => {
        setApplicationVal({ ...applicationVal, [e.target.name]: e.target.value });
    }

    const submitHandler = (e) => {
        e.preventDefault();

        if (isAgree === false) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please check our terms and conditions!"
            });
        }

        if (!applicationVal.name || !applicationVal.email || !applicationVal.phone || !applicationVal.address) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Mandatory field is missing!"
            });
        };

        if (!applicationVal.interestedfor) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please choose what you are interested for"
            });
        };

        if (!applicationVal.country) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please choose interested country"
            });
        };

        if (applicationVal.phone.length !== 10) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Phone number shound include 10 digits!"
            });
        };

        if (applicationVal.email.includes("@gmail.com") !== true) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Email is invalid!"
            });
        };

        const myForm = new FormData()
        myForm.append("name", applicationVal.name);
        myForm.append("email", applicationVal.email);
        myForm.append("phone", applicationVal.phone);
        myForm.append("address", applicationVal.address);
        myForm.append("interestedfor", applicationVal.interestedfor);
        myForm.append("country", applicationVal.country);
        myForm.append("message", applicationVal.message);

        return dispatch(createApplication(myForm));
    }

    useEffect(() => {
        if (error) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
        }
        if (success) {
            dispatch(resetNew());
            navigate("/success");
        }
    }, [dispatch, success, navigate, error]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="application-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="application-form-container">
                                <div className="apply-img">
                                    <img src={logo} draggable={false} alt="logo" data-aos="fade-up" />
                                </div>
                                <div className="application-inner">
                                    {
                                        isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                                    }
                                    <h3>APPLY NOW -</h3>
                                    <form className="global-form">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-group">
                                                        <label className="label">Enter Your Name</label><span>*</span>
                                                        <input
                                                            className="input"
                                                            type="text"
                                                            placeholder="Enter Full Name"
                                                            name="name"
                                                            value={applicationVal.name}
                                                            onChange={(e) => onChangeHandler(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group">
                                                        <label>Email</label><span>*</span>
                                                        <input
                                                            type="email"
                                                            placeholder="Ex - studymate.edu.np@gmail.com"
                                                            name="email"
                                                            value={applicationVal.email}
                                                            onChange={(e) => onChangeHandler(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group">
                                                        <label>Mobile No.</label><span>*</span>
                                                        <input
                                                            type="number"
                                                            placeholder="+977 - Mobile No"
                                                            name="phone"
                                                            value={applicationVal.phone}
                                                            onChange={(e) => onChangeHandler(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group">
                                                        <label>Address</label><span>*</span>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Address"
                                                            name="address"
                                                            value={applicationVal.address}
                                                            onChange={(e) => onChangeHandler(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group">
                                                        <label>Interested For</label><span>*</span>
                                                        <select
                                                            name="interestedfor"
                                                            value={applicationVal.interestedfor}
                                                            onChange={(e) => onChangeHandler(e)}
                                                        >
                                                            <option value="">Select Interest</option>
                                                            <option value="Study Abroad">Study Abroad</option>
                                                            <option value="Preparation classes">Preparation Classes</option>
                                                            <option value="Language classes">Language Classes</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        applicationVal.interestedfor === "Study Abroad"
                                                            ?
                                                            <div className="input-group">
                                                                <label>Interested Country</label><span>*</span>
                                                                <select
                                                                    name="country"
                                                                    value={applicationVal.country}
                                                                    onChange={(e) => onChangeHandler(e)}
                                                                >
                                                                    <option value="">Select Country</option>
                                                                    <option value="Japan">Japan</option>
                                                                    <option value="Australia">Australia</option>
                                                                    <option value="USA">USA</option>
                                                                    <option value="Canada">Canada</option>
                                                                    <option value="Finland">Finland</option>
                                                                    <option value="Hungary">Hungary</option>
                                                                </select>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        applicationVal.interestedfor === "Preparation classes"
                                                            ?
                                                            <div className="input-group">
                                                                <label>Choose Preparation Class</label><span>*</span>
                                                                <select
                                                                    name="country"
                                                                    value={applicationVal.country}
                                                                    onChange={(e) => onChangeHandler(e)}
                                                                >
                                                                    <option value="">Select Preparation Class</option>
                                                                    <option value="IELTS">IELTS</option>
                                                                    <option value="PTE">PTE</option>
                                                                    <option value="NAT">NAT</option>
                                                                </select>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        applicationVal.interestedfor === "Language classes"
                                                            ?
                                                            <div className="input-group">
                                                                <label>Choose Language Class</label><span>*</span>
                                                                <select
                                                                    name="country"
                                                                    value={applicationVal.country}
                                                                    onChange={(e) => onChangeHandler(e)}
                                                                >
                                                                    <option value="">Select Language Class</option>
                                                                    <option value="English">English</option>
                                                                    <option value="Japanese">Japanese</option>
                                                                    <option value="Korean">Korean</option>
                                                                </select>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="input-group">
                                                        <label>Anything to say</label>
                                                        <textarea
                                                            name="message"
                                                            value={applicationVal.message}
                                                            onChange={(e) => onChangeHandler(e)}
                                                            cols="10"
                                                            rows="5"
                                                        >
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="submit-btn">
                                                        <button onClick={submitHandler}>Apply <i className="fa fa-paper-plane"></i></button>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="iaccept">
                                                        <input
                                                            type="checkbox"
                                                            required
                                                            onChange={(e) => setIsAgree(e.target.checked)}
                                                        /> <span>I accept all terms and conditions of StudyMate International Educational Consultancy Pvt. Ltd.</span>
                                                        <p>* - Field is required</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="apply-features">
                                <div className="apply-featured">
                                    <h3>Hey, Are you planing for Abroad Study?</h3>
                                    <h4>Here we are, Join us for,</h4>
                                    <ul>
                                        <li><i className="fa fa-angle-right"></i>Abroad Study</li>
                                        <li><i className="fa fa-angle-right"></i>Language Classes</li>
                                        <li><i className="fa fa-angle-right"></i>IELTS Classes</li>
                                        <li><i className="fa fa-angle-right"></i>PTE Classes</li>
                                        <li><i className="fa fa-angle-right"></i>Counseling</li>
                                        <li><i className="fa fa-angle-right"></i>VISA Processing</li>
                                    </ul>
                                </div>
                                <div className="apply-feature-inner">
                                    <img src={apply} alt="feat" draggable="false" data-aos="fade-up" />
                                </div>
                                <div className="join-us-arrow">
                                    <img src={arrow} alt="arrow-img" draggable="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Application;