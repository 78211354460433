import React from 'react';
import studymate_wave from '../../../assets/images/design/studymate_wave.svg';

const BranchHemja = () => {
    return (
        <>
            <div className="studymate-branch">
                <img className="branch_wave" src={studymate_wave} alt="wave" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="brand-heading">
                                <p>Studymate Branch</p>
                                <h2>HEMJA</h2>
                                <div className="branch-animation-div">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            <div className="branch-detail-parent">
                                <div className="branch-detail-childs">
                                    <i className="fa fa-phone"></i>
                                    <p>+977-61-590082, +977-9856077527</p>
                                </div>
                                <div className="branch-detail-childs">
                                    <i className="fa fa-map-marker-alt"></i>
                                    <p>Pokhara-25, Hemja, Kaski</p>
                                </div>
                                <div className="branch-detail-childs">
                                    <i className="fa fa-envelope"></i>
                                    <p>info@studymate.edu.np</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BranchHemja