import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import teacher from '../../assets/images/svgs/teacher.svg';
import writting from '../../assets/images/svgs/writing.svg';
import refer from '../../assets/images/svgs/refer.svg';
import reliability from '../../assets/images/svgs/reliability.svg';
import countries from '../../assets/images/svgs/countries.svg';
import Slider from '../../components/slider/Slider';
import { getSlider } from '../../state/actions/sliderAction';
import Loader from '../../components/loader/Loader';

const Banner = () => {
    const dispatch = useDispatch();

    const { loading, sliders } = useSelector(state => state.sliders);

    useEffect(() => {
        dispatch(getSlider());
    }, [dispatch]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="banner-section">
                <div className="social-media-links">
                    <ul>
                        <a href="https://www.facebook.com/studymate.edu.np" target="_blank" rel="noreferrer">
                            <li>
                                <i className="fab fa-facebook-f"></i>
                            </li>
                        </a>
                        <a href="https://www.instagram.com/studymate.edu.np" target="_blank" rel="noreferrer">
                            <li>
                                <i className="fab fa-instagram"></i>
                            </li>
                        </a>
                        <a href="https://www.tiktok.com/@studymate.edu.np" target="_blank" rel="noreferrer">
                            <li>
                                <i className="fab fa-tiktok"></i>
                            </li>
                        </a>
                        <a href="https://www.linkedin.com/in/studymate/" target="_blank" rel="noreferrer">
                            <li>
                                <i className="fab fa-linkedin-in"></i>
                            </li>
                        </a>
                    </ul>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            {/* slider::begin */}
                            <div className="slider-wrapper">
                                <Slider data={sliders} autoSlide={true} slidingTime={3000} />
                            </div>
                            {/* slider::end */}
                        </div>
                        <div className="col-md-4">
                            <div className="banner-info">
                                <div className="banner-side-info">
                                    <div className="banner-side-info-parent" data-aos="fade-up">
                                        <div className="banner-side-info-childs">
                                            <img src={teacher} alt="banner-info-img" />
                                            <h3>Preparation Classes</h3>
                                            <div className="sliding-eff"></div>
                                        </div>
                                        <div className="banner-side-info-childs">
                                            <img src={writting} alt="banner-info-img" />
                                            <h3>Language Classes</h3>
                                            <div className="sliding-eff"></div>
                                        </div>
                                    </div>
                                    <div className="banner-side-info-parent" data-aos="fade-up">
                                        <div className="banner-side-info-childs">
                                            <img src={refer} alt="banner-info-img" />
                                            <h3>Counseling</h3>
                                            <div className="sliding-eff"></div>
                                        </div>
                                        <div className="banner-side-info-childs">
                                            <img src={reliability} alt="banner-info-img" />
                                            <h3>VISA Processing</h3>
                                            <div className="sliding-eff"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="banner-featured-image">
                                    <img src={countries} alt="countries" draggable="false" />
                                </div>
                                <div className="banner-contact">
                                    <h3>CONTACT US</h3>
                                    <p>We provide various services for abroad study. Our service is the best known, most....</p>
                                    <div className="banner-contact-us-btn">
                                        <Link to="/contact">Contact Us <i className="fa fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Banner