import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getService } from '../../state/actions/serviceAction';
import Loading from '../../components/loader/Loader';
import countries from '../../assets/images/svgs/countries.svg';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

const Preparation = () => {
    const dispatch = useDispatch();
    const [counterOn, setCounterOn] = useState(false);

    const { loading, services } = useSelector(state => state.services);

    useEffect(() => {
        dispatch(getService("all"));
    }, [dispatch]);
    return (
        <>
            {loading ? <Loading /> : <></>}
            <section className="preparation-classes-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="preparation-class-wrapper">
                                <h3>PREPARATION CLASSES</h3>
                                <ul className="border-left">
                                    <li>
                                        {
                                            services && services.filter(opt => opt.category === "Preparation Classes").map((data, index) => (
                                                <Link to={`/serviceDetail/${data.id}`} key={index}>
                                                    <h4>{data.title}</h4>
                                                </Link>
                                            ))
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="preparation-featured-image">
                                <img src={countries} alt="featured" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="preparation-class-wrapper">
                                <h3>LANGUAGE CLASSES</h3>
                                <ul className="border-right">
                                    <li>
                                        {
                                            services && services.filter(opt => opt.category === "Language Classes").map((data, index) => (
                                                <Link to={`/serviceDetail/${data.id}`} key={index}>
                                                    <h4>{data.title}</h4>
                                                </Link>
                                            ))
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                <div className="progress-bar-num">
                                    <p>Assure Almost</p>
                                    {/* <h3>{counterOn && <CountUp start={0} end={100} duration={1.5} />}% <span className="almost-txt">ALMOST</span></h3> */}
                                    <h3>{counterOn && <CountUp start={0} end={100} duration={1.5} />}%</h3>
                                    <p>Student Satisfaction</p>
                                </div>
                                <div className="progressbar-wrapper">
                                    <div className={counterOn ? "progress-bar-status progress-bar-status-alt width-100" : "progress-bar-status progress-bar-status-alt"}></div>
                                </div>
                                <div className="progressbar-wrapper">
                                    <div className={counterOn ? "progress-bar-status width-100" : "progress-bar-status"}></div>
                                </div>
                            </ScrollTrigger>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Preparation