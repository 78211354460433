import React from 'react'
import About from './About'
import Application from './Application'
import Banner from './Banner'
import BrandMessage from './BrandMessage'
import Contact from './Contact'
import MessageFromMD from './MessageFromMD'
import Preparation from './Preparation'
import StudyAbroad from './StudyAbroad'
import Team from './Team'
import Review from './Review'

const Home = () => {
  return (
    <>
      <Banner />
      <BrandMessage />
      <MessageFromMD />
      <StudyAbroad />
      <Preparation />
      <About />
      <Application />
      <Contact />
      <Review />
      <Team />
    </>
  )
}

export default Home