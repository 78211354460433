import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Notify from '../notify/Notify';
import img_pre from '../../../assets/images/img-pre.png';
import Loader from '../../../components/loader/Loader';
import { clearErrors, detail_review, resetDetail, resetUpdate, updat_review } from '../../../state/actions/reviewAction';

const UpdateReview = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { loading, review, error } = useSelector(state => state.reviewDetail);
    const { loading: updateLoading, isUpdated, error: updateError } = useSelector(state => state.review);


    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [serviceVal, setServiveVal] = useState({
        name: "",
        orientation: "",
        rating: "",
        visibility: "",
        review: ""
    });

    const [file, setFile] = useState(null);
    const [oldFile, setOldFile] = useState(null);

    const onChangeHandler = (e) => {
        setServiveVal({ ...serviceVal, [e.target.name]: e.target.value });
    }

    const submitHandller = (e) => {
        e.preventDefault();

        if (!serviceVal.name || !serviceVal.orientation || !serviceVal.review || !serviceVal.rating || !serviceVal.visibility) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Mandatory field is missing!"
            });
        };

        if (file) {
            if ((file.size / 1024) >= 800) {
                return setIsMessage({
                    isThereMessage: true,
                    messageType: "danger",
                    message: "File size should be less than '800kb'!"
                });
            };
        }

        const myForm = new FormData();
        myForm.append("name", serviceVal.name);
        myForm.append("orientation", serviceVal.orientation);
        myForm.append("review", serviceVal.review);
        myForm.append("rating", serviceVal.rating);
        myForm.append("visibility", serviceVal.visibility);

        if (file) {
            myForm.append("image", file);
        }

        dispatch(updat_review(id, myForm));
    }

    useEffect(() => {
        dispatch(detail_review(id));

        setServiveVal({
            name: review.name,
            orientation: review.orientation,
            rating: review.rating,
            visibility: review.visibility,
            review: review.review
        });
        setOldFile(review.image);

        if (isUpdated) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: isUpdated.message
            });
            dispatch(resetDetail());
            dispatch(resetUpdate());
            navigate("/dashboard/manageReviews");
        }

        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors());
        }

        if (updateError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: updateError
            });
            dispatch(clearErrors());
        }
    }, [
        id,
        error,
        navigate,
        dispatch,
        updateError,
        isUpdated,
        review.name,
        review.orientation,
        review.rating,
        review.visibility,
        review.review,
        review.image
    ]);

    return (
        <>
            {loading || updateLoading ? <Loader /> : <></>}
            <div className="dashboard-forms">
                <h4>UPDATE SERVICE</h4>
                {
                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <form>
                    <div className="input-group">
                        <label className="dashboard-label">Name</label>
                        <input
                            type="text"
                            className="dashboard-input"
                            placeholder="Enter name"
                            name="name"
                            value={serviceVal.name}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Orientation <span>(Alert! - This will define whiic data come first)</span></label>
                        <input
                            type="number"
                            className="dashboard-input"
                            placeholder="Enter Orientation"
                            name="orientation"
                            value={serviceVal.orientation}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Rating</label>
                        <select name="rating" value={serviceVal.rating} onChange={(e) => onChangeHandler(e)} className="dashboard-input">
                            <option>- Select Rating -</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Visibility</label>
                        <select name="visibility" value={serviceVal.visibility} onChange={(e) => onChangeHandler(e)} className="dashboard-input">
                            <option>- Select Visibility -</option>
                            <option value="visible">Visible</option>
                            <option value="hide">Hide</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Visibility</label>
                        <textarea
                            cols="30"
                            rows="10"
                            className="dashboard-input"
                            placeholder="Enter Orientation"
                            name="review"
                            value={serviceVal.review}
                            onChange={(e) => onChangeHandler(e)}
                        ></textarea>
                    </div>
                    <div className="input-group-type-file">
                        <label className="dashboard-label">Thumbnail <span>(Alert! - File size should be less then "500kb")</span></label>
                        <input
                            type="file"
                            className="dashboard-input-type-file"
                            accept="image/*"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>
                    {
                        file && file
                            ?
                            <div className="form-img-preview">
                                <img src={URL.createObjectURL(file)} draggable={false} alt="preview" />
                                <div className="file-remove-btn">
                                    <button onClick={() => setFile(null)}>REMOVE <i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            :
                            <div className="form-img-preview">
                                <img src={img_pre} draggable={false} alt="preview" />
                            </div>
                    }
                    {
                        oldFile && oldFile
                            ?
                            <div className="form-img-preview">
                                <img src={oldFile} draggable={false} alt="preview" />
                            </div>
                            :
                            <></>
                    }
                    <div className="dashboard-submit-btn100">
                        <button onClick={submitHandller}>UPDATE</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default UpdateReview