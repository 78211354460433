import {
    CREATE_REVIEW_REQUEST,
    CREATE_REVIEW_SUCCESS,
    CREATE_REVIEW_FAIL,
    GET_REVIEW_REQUEST,
    GET_REVIEW_SUCCESS,
    GET_REVIEW_FAIL,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAIL,
    DETAIL_REVIEW_REQUEST,
    DETAIL_REVIEW_SUCCESS,
    DETAIL_REVIEW_FAIL,
    UPDATE_REVIEW_REQUEST,
    UPDATE_REVIEW_SUCCESS,
    UPDATE_REVIEW_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
    RESET_DETAIL,
    RESET_UPDATE,
} from '../constants/reviewConstant';

export const reviewsReducer = (state = { reviews: [] }, action) => {
    switch (action.type) {
        case GET_REVIEW_REQUEST:
            return {
                loading: true,
                reviews: []
            };

        case GET_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                reviews: action.payload.data.data
            };

        case GET_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newReviewReducer = (state = { review: {} }, action) => {
    switch (action.type) {
        case CREATE_REVIEW_REQUEST:
            return {
                loading: true,
            };

        case CREATE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const reviewReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_REVIEW_REQUEST:
        case UPDATE_REVIEW_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case UPDATE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload.data,
            };

        case DELETE_REVIEW_FAIL:
        case UPDATE_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case RESET_UPDATE:
            return {
                ...state,
                isUpdated: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const reviewDetailReducer = (state = { review: {} }, action) => {
    switch (action.type) {
        case DETAIL_REVIEW_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_REVIEW_SUCCESS:
            return {
                loading: false,
                review: action.payload.data.data
            };

        case DETAIL_REVIEW_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case RESET_DETAIL:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};