import React, { useEffect } from 'react';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import { resetDetail, detailApplication } from '../../../state/actions/applicationAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../../components/loader/Loader';
import { detail_review } from '../../../state/actions/reviewAction';

const ViewReview = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { loading, review } = useSelector((state) => state.reviewDetail);

    useEffect(() => {
        dispatch(detail_review(id));

        return () => {
            dispatch(resetDetail());
        }
    }, [dispatch, id]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Review Detail" />
            <div className="dashboard-detail-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="dashboard-detail-page-wrapper">
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Application_ID -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{review?.id}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Rating -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{review?.rating}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Orientation -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{review?.orientation}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Visibility -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{review?.visibility}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Review -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{review?.review}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Application received -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{moment(review?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewReview;