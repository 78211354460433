import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import moment from 'moment';
import userProfile from '../../../assets/images/MD.PNG';

const WebTrafic = ({ count }) => {

    const { user } = useSelector((state) => state.users);

    const lineState = {
        labels: ["Initial Web Trafic", "Trafic Till Now"],
        datasets: [
            {
                label: "TOTAL TRAFIC",
                backgroundColor: ["purple"],
                hoverBackgroundColor: ["rgb(197, 72, 49)"],
                data: [0, count],
            },
        ],
    };

    return (
        <>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-md-8">
                        {/* line chart::begin */}
                        <div className="lineChart shadow-container">
                            <Line data={lineState} />
                        </div>
                        {/* line chart::end */}
                    </div>
                    <div className="col-md-4">
                        <div className="admin-profile-info shadow-container-inset">
                            <div className="admin-profile-image">
                                <img src={userProfile} draggable={false} alt="profile" />
                            </div>
                            <div className="admin-info">
                                <h3>{user && user.name}</h3>
                                <p>Admin</p>
                                <div className="admin-info-details">
                                    <div className="admin-info-detail">
                                        <h2>Admin:</h2>
                                    </div>
                                    <div className="admin-info-detail">
                                        <h4>{user && user.name}</h4>
                                    </div>
                                </div>
                                <div className="admin-info-details">
                                    <div className="admin-info-detail">
                                        <h2>Email:</h2>
                                    </div>
                                    <div className="admin-info-detail">
                                        <h4>stu********</h4>
                                    </div>
                                </div>
                                <div className="admin-info-details">
                                    <div className="admin-info-detail">
                                        <h2>Address:</h2>
                                    </div>
                                    <div className="admin-info-detail">
                                        <h4>{user && user.address}</h4>
                                    </div>
                                </div>
                                <div className="admin-info-details">
                                    <div className="admin-info-detail">
                                        <h2>Role:</h2>
                                    </div>
                                    <div className="admin-info-detail">
                                        <h4>admin</h4>
                                    </div>
                                </div>
                                <div className="admin-info-details">
                                    <div className="admin-info-detail">
                                        <h2>Joined Date:</h2>
                                    </div>
                                    <div className="admin-info-detail">
                                        <h4>{moment(user && user.createdAt).format('Do MMMM YYYY')}</h4>
                                    </div>
                                </div>
                                <Link to="/dashboard/changePassword" className="global-send-btn-with-a mt-2">Change Password</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WebTrafic;