import { Link } from 'react-router-dom';
import footerLogo from '../../assets/images/studyMateWhite.png';
import top_deco_img from "./../../assets/images/svgs/top-deco-svg.svg";

const Footer = () => {

    return (
        <>
            {/* footer::begin */}
            <img className="brand-stripe" src={top_deco_img} alt="top-deco" />
            <section className="footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-logo">
                                <div className="footer-logo-img">
                                    <img src={footerLogo} alt="footer-logo" draggable="false" />
                                </div>
                            </div>
                            <div className="footer-contact-info">
                                <ul>
                                    <li><i className="fa fa-map-marker-alt"></i>Pokhara-1, Bagar, Kaski</li>
                                    <li><a href="tel:+977-61-570898" rel="noreferrer"><i className="fa fa-phone"></i>+977-61-570898</a></li>
                                    <li><a href="https://www.gmail.com/" target="_blank" rel="noreferrer"><i className="fa fa-envelope"></i>info@studymate.edu.np, studymate.edu.np@gmail.com</a></li>
                                </ul>
                            </div>
                            <div className="footer-social-icons">
                                <ul>
                                    <a href="https://www.facebook.com/studymate.edu.np" target="_blank" rel="noreferrer">
                                        <li>
                                            <i className="fab fa-facebook-f"></i>
                                        </li>
                                    </a>
                                    <a href="https://www.instagram.com/studymate.edu.np" target="_blank" rel="noreferrer">
                                        <li>
                                            <i className="fab fa-instagram"></i>
                                        </li>
                                    </a>
                                    <a href="https://www.tiktok.com/@studymate.edu.np" target="_blank" rel="noreferrer">
                                        <li>
                                            <i className="fab fa-tiktok"></i>
                                        </li>
                                    </a>
                                    <a href="https://www.linkedin.com/in/studymate/" target="_blank" rel="noreferrer">
                                        <li>
                                            <i className="fab fa-linkedin-in"></i>
                                        </li>
                                    </a>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="footer-links">
                                <h3>QUICK LINKS</h3>
                                <ul>
                                    <li><Link to="/"><i className="fa fa-angle-right"></i>Home</Link></li>
                                    <li><Link to="/about"><i className="fa fa-angle-right"></i>About Us</Link></li>
                                    <li><Link to="/contact"><i className="fa fa-angle-right"></i>Contact US</Link></li>
                                    <li><Link to="/gallery"><i className="fa fa-angle-right"></i>Gallery</Link></li>
                                    <li><Link to="/application"><i className="fa fa-angle-right"></i>Apply Now</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="footer-links">
                                <h3>SERVICES</h3>
                                <ul>
                                    <li><a href="/#"><i className="fa fa-angle-right"></i>Abroad Study</a></li>
                                    <li><a href="/#"><i className="fa fa-angle-right"></i>Preparation Classes</a></li>
                                    <li><a href="/#"><i className="fa fa-angle-right"></i>Language Classes</a></li>
                                    <li><a href="/#"><i className="fa fa-angle-right"></i>Counseling</a></li>
                                    <li><a href="/#"><i className="fa fa-angle-right"></i>Visa Processing</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="connect-with-us-facebook">
                                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fstudymate.edu.np&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" title="studymate_facebook" width="340" height="500" style={{ border: "none", overflow: "hidden" }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="copy-right">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copy-right-parent">
                                <div className="copy-right-childs">
                                    <p>Copyright © {new Date().getFullYear()} StudyMate</p>
                                </div>
                                <div className="copy-right-childs">
                                    <p>Developed by <a href="https://codesastra.com.np/" target="_blank" rel="noreferrer">Codesastra Softwares Pvt. Ltd</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* footer::end */}
        </>
    )
}

export default Footer;