import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import Notify from '../../pages/dashboard/notify/Notify';
import { createContact, resetNew } from '../../state/actions/contactAction';
import decoImg from '../../assets/images/design/purplebg.png';
import ScrollTrigger from 'react-scroll-trigger';

const Contact = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [counterOn, setCounterOn] = useState(false);

    const { loading, error, success } = useSelector(state => state.newContact);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [contactVal, setContactVal] = useState({
        name: "",
        email: "",
        phone: "",
        interestedfor: "",
        message: ""
    });

    const onChangeHandler = (e) => {
        setContactVal({ ...contactVal, [e.target.name]: e.target.value });
    }

    const submithandler = (e) => {
        e.preventDefault();

        if (!contactVal.name || !contactVal.email || !contactVal.phone || !contactVal.message) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Mandatory field is missing!"
            });
        };

        if (!contactVal.interestedfor) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please choose what you are interested for"
            });
        };

        if (contactVal.phone.length !== 10) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Phone number shound include 10 digits!"
            });
        };

        if (contactVal.email.includes("@gmail.com") !== true) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Email is invalid!"
            });
        };

        const myForm = new FormData()
        myForm.append("name", contactVal.name);
        myForm.append("email", contactVal.email);
        myForm.append("phone", contactVal.phone);
        myForm.append("interestedfor", contactVal.interestedfor);
        myForm.append("message", contactVal.message);

        dispatch(createContact(myForm));
    }

    useEffect(() => {
        if (error) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
        }

        if (success) {
            dispatch(resetNew());
            navigate("/successMessage");
        }
    }, [dispatch, success, navigate, error]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="contact-section">
                <img src={decoImg} alt="deco" className="contact-deco-image" />
                <img src={decoImg} alt="deco" className="contact-deco-image-alt" />
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="studymate-header-center-only">
                                    <h4>Get in touch!</h4>
                                    <p>Contact us for any queries</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className={counterOn ? "contact-info-wrapper fadeUpAni" : "contact-info-wrapper"} style={{ "--i": 1 }}>
                                    <i className="fa fa-phone"></i>
                                    <h3>+977-61-570898</h3>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={counterOn ? "contact-info-wrapper fadeUpAni" : "contact-info-wrapper"} style={{ "--i": 2 }}>
                                    <i className="fa fa-envelope"></i>
                                    <h3>info@studymate.edu.np</h3>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={counterOn ? "contact-info-wrapper fadeUpAni" : "contact-info-wrapper"} style={{ "--i": 3 }}>
                                    <i className="fa fa-map-marker-alt"></i>
                                    <h3>Pokhara-1, Bagar, Kaski</h3>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={counterOn ? "contact-info-wrapper fadeUpAni" : "contact-info-wrapper"} style={{ "--i": 4 }}>
                                    <i className="fa fa-globe"></i>
                                    <h3>studymate.edu.np</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollTrigger>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="callback-form">
                                {
                                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                                }
                                <div className="drop-a-message">
                                    <h3>Call Back Request, Or just say hy!</h3>
                                    <p>We will contact you with in 24 hours</p>
                                </div>
                                <form className="global-form">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <label className="label">Enter Name</label><span>*</span>
                                                    <input
                                                        type="text"a
                                                        placeholder="Your Name"
                                                        name="name"
                                                        value={contactVal.name}
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <label className="label">Email Address</label><span>*</span>
                                                    <input
                                                        type="email"
                                                        placeholder="Ex - studymate.edu.np@gmail.com"
                                                        name="email"
                                                        value={contactVal.email}
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <label className="label">Mobile No.</label><span>*</span>
                                                    <input
                                                        type="number"
                                                        placeholder="+977 - Mobile no."
                                                        name="phone"
                                                        value={contactVal.phone}
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <label className="label">Interest For</label><span>*</span>
                                                    <select
                                                        name="interestedfor"
                                                        value={contactVal.interestedfor}
                                                        onChange={(e) => onChangeHandler(e)}
                                                    >
                                                        <option value="">Select Interest</option>
                                                        <option value="Abroad Study">Study Abroad</option>
                                                        <option value="Preparation classes">Preparation Classes</option>
                                                        <option value="Language classes">Language Classes</option>
                                                        <option value="NAT">General Counseling</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <label className="label">Anything to say</label>
                                                    <textarea
                                                        rows="3"
                                                        placeholder="Message / Feedback"
                                                        name="message"
                                                        value={contactVal.message}
                                                        onChange={(e) => onChangeHandler(e)}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="submit-btn ">
                                                    <button onClick={submithandler}>SEND <i className="fa fa-paper-plane"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="studymate-location-map">
                                <iframe title="my_map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d439.36751188471715!2d83.98736775360427!3d28.239497866085518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995953b972ac76b%3A0x4b0885d4a8ee924d!2sStudyMate%20International%20Educational%20Consultancy%20Pvt.%20Ltd.!5e0!3m2!1sne!2snp!4v1653743126979!5m2!1sne!2snp" width="600" height="450" style={{ border: "0" }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact