import {
    CREATE_REVIEW_REQUEST,
    CREATE_REVIEW_SUCCESS,
    CREATE_REVIEW_FAIL,
    GET_REVIEW_REQUEST,
    GET_REVIEW_SUCCESS,
    GET_REVIEW_FAIL,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAIL,
    DETAIL_REVIEW_REQUEST,
    DETAIL_REVIEW_SUCCESS,
    DETAIL_REVIEW_FAIL,
    UPDATE_REVIEW_REQUEST,
    UPDATE_REVIEW_SUCCESS,
    UPDATE_REVIEW_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
    RESET_DETAIL,
    RESET_UPDATE,
} from '../constants/reviewConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get_review::begin
export const getReview = () => async (dispatch) => {
    try {
        dispatch({ type: GET_REVIEW_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/review/getReview`, config);

        dispatch({ type: GET_REVIEW_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_REVIEW_FAIL, payload: error.response.data.message });
    }
}
// get_review::end

// create_review::begin
export const creat_review = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_REVIEW_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.post(`${globalLink}/api/review/createReview`, data, config);

        dispatch({ type: CREATE_REVIEW_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_REVIEW_FAIL, payload: error.response.data.message });
    }
}
// create_review::end

// delete_review::begin
export const delete_review = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_REVIEW_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.delete(`${globalLink}/api/review/deleteReview/${id}`, config);

        dispatch({ type: DELETE_REVIEW_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_REVIEW_FAIL, payload: error.response.data.message });
    }
}
// delete_review::end

// detail_review::begin
export const detail_review = (id) => async (dispatch) => {
    try {
        dispatch({ type: DETAIL_REVIEW_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/review/detailReview/${id}`, config);

        dispatch({ type: DETAIL_REVIEW_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DETAIL_REVIEW_FAIL, payload: error.response.data.message });
    }
}
// detail_REVIEW::end

// update_REVIEW::begin
export const updat_review = (id, data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_REVIEW_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.put(`${globalLink}/api/review/updateReview/${id}`, data, config);

        dispatch({ type: UPDATE_REVIEW_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: UPDATE_REVIEW_FAIL, payload: error.response.data.message });
    }
}
// update_REVIEW::end

// resets::begin
export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetUpdate = () => async (dispatch) => {
    dispatch({ type: RESET_UPDATE });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};

export const resetDetail = () => async (dispatch) => {
    dispatch({ type: RESET_DETAIL });
};

// resets::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end