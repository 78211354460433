import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, delete_review, getReview, resetDelete } from '../../../state/actions/reviewAction';
import Loader from '../../../components/loader/Loader';
import moment from 'moment';
import Notify from '../notify/Notify';
import { useNavigate } from 'react-router-dom';

const ReviewIndex = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { loading, reviews, error } = useSelector(state => state.reviews);
    const { loading: deleteLoading, error: deleteError, isDeleted, message: deleteMessage } = useSelector(state => state.review);

    const deleteReviews = (id) => {
        return dispatch(delete_review(id))
    }

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const navigateToDetailPage = (id) => {
        return navigate(`/dashboard/viewReview/${id}`);
    }

    const navigateToUpdatePage = (id) => {
        return navigate(`/dashboard/updateReview/${id}`);
    }

    useEffect(() => {
        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors());
        }

        if (deleteError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: deleteError
            });
            dispatch(clearErrors());
        }

        if (isDeleted) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: deleteMessage
            });
            dispatch(resetDelete());
        }

        dispatch(getReview());
    }, [dispatch, error, isDeleted, deleteError, deleteMessage]);

    return (
        <>
            {loading || deleteLoading ? <Loader /> : <></>}
            <section className="dashboard-operation-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dashboard-data-table">
                                {
                                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                                }
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.N</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Rating</th>
                                            <th>Origntation</th>
                                            <th>Visibility</th>
                                            <th>Created At</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            reviews?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td className="table-image">
                                                        <img src={data?.image} draggable={false} alt="table" />
                                                    </td>
                                                    <td>{data?.name}</td>
                                                    <td>{data?.rating}</td>
                                                    <td>{data?.orientation}</td>
                                                    <td>{data?.visibility}</td>
                                                    <td>{moment(data?.updatedAt).format('MMM-D, YYYY')}</td>
                                                    <td className="table-action">
                                                        <button className="view" onClick={() => navigateToDetailPage(data?.id)}>VIEW</button>
                                                        <button className="update" onClick={() => navigateToUpdatePage(data?.id)}>UPDATE</button>
                                                        <button className="delete" onClick={() => deleteReviews(data?.id)}>DELETE</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReviewIndex;